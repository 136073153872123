<template>
    <div class="grid">
        <div class="page-list-body">
            <div class="row">
                <div class="col l-6 m-6 c-12">
                    <md-field :class="{'md-invalid': submitted && $v.oldPassword.$error }">
                        <label for="password">Mật khẩu cũ</label>
                        <md-input name="oldPassword" v-model="oldPassword" type="password"></md-input>
                        <span class="md-error" v-if="submitted && !$v.oldPassword.required">Vui lòng nhập mật khẩu cũ</span>
                    </md-field>

                    <md-field :class="{'md-invalid': submitted && $v.password.$error }">
                        <label for="password">Mật khẩu mới</label>
                        <md-input name="password" v-model="password" minlength="6" type="password"></md-input>
                        <span class="md-error" v-if="submitted && !$v.password.required">Vui lòng nhập mật khẩu mới</span>
                        <span class="md-error" v-if="submitted && !$v.password.minLength">Tên đăng nhập ít nhất 6 ký tự</span>
                    </md-field>

                    <md-field :class="{'md-invalid': submitted && $v.confirmPassword.$error }">
                        <label for="confirm-password">Nhập lại mật khẩu</label>
                        <md-input name="confirmPassword" minlength="6" v-model="confirmPassword" type="password"></md-input>
                        <span class="md-error" v-if="submitted && !$v.confirmPassword.required">Vui lòng nhập lại mật khẩu mới</span>
                        <span class="md-error" v-else-if="!$v.confirmPassword.sameAsPassword">Nhập lại mật khẩu không đúng</span>
                    </md-field>
                </div>
            </div>
            <div class="row">
                <div class="col l-12 m-12 c-12 action">
                    <md-button v-on:click="submit()" class="md-raised md-accent">Lưu</md-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import userService from './../../api/userService';
import messageBox from './../../utils/messageBox';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default({
    metaInfo: {
        title: 'Thông tin tài khoản',
    },
    data() {
        return {
            title: '',
            loadding: false,
            submitted: false,
            oldPassword: '',
            password: '',
            confirmPassword: ''
        }
    },
    created() {
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.setLoading(true);
            userService.updatePassword(this.oldPassword, this.password).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thay đổi mật khẩu thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },
    },
    validations: {
        oldPassword: { required},
        password: { required, minLength: minLength(6)},
        confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
})
</script>

<style scoped>
    .action{
        padding: 20px 0;
    }
</style>