import httpClient  from '../api';
const API_CONTROLLER = 'user/';

export default {
    edit(data) {
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getInfo() {
        let url = API_CONTROLLER + 'get-info';
        return httpClient.get(url);
    },

    
    updatePassword(oldPassword, newPassword){
        let url = API_CONTROLLER + 'update-password';
        return httpClient.get(url, {
            params: {
                oldPassword: oldPassword, 
                newPassword: newPassword
            }
        });
    },

}