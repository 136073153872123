<template>
    <div class="grid">
        <div class="page-list-body">
            <div class="row">
                <div class="col l-6 m-6 c-12">
                    <md-field>
                        <label for="role">Nhóm quyền</label>
                        <md-input :disabled="true" name="role" v-model="entity.role.roleName"></md-input>
                    </md-field>

                    <md-field>
                        <label for="user-name">Tên đăng nhập</label>
                        <md-input :disabled="true" name="user-name" v-model="entity.userName" minlength="6"></md-input>
                    </md-field>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.firstName.$error }">
                                <label for="first-name">Họ và tên đệm</label>
                                <md-input name="first-name" v-model="entity.firstName" ></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.firstName.required">Vui lòng nhập họ và tên đệm</span>
                            </md-field>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.lastName.$error }">
                                <label for="last-name">Tên</label>
                                <md-input name="last-name" v-model="entity.lastName" ></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.lastName.required">Vui lòng nhập tên</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col l-12 m-12 c-12 action">
                    <md-button v-on:click="submit()" class="md-raised md-accent">Lưu</md-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import userService from './../../api/userService';
import messageBox from './../../utils/messageBox';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default({
    metaInfo: {
        title: 'Thông tin tài khoản',
    },
    data() {
        return {
            title: '',
            submitted: false,
            entity: { roleId: 0, role: { id:0, roleName: '' }, password: 'password', userName: '', firstName: '', lastName: ''}
        }
    },
    created() {
        this.getInfo();
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.edit();
        },

        edit(){
            this.setLoading(true);
            userService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thông tin thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getInfo(){
            userService.getInfo().then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.entity.password = 'password';
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
    },
    validations: {
        entity: { 
            firstName: { required},
            lastName: { required}
        }
    },
})
</script>

<style scoped>
    .action{
        padding: 0;
    }
</style>