<template>
  <div class="page-content">
    <md-app md-waterfall md-mode="fixed">
      <md-app-toolbar class="md-primary">
        <span class="md-title">{{title}}</span>
      </md-app-toolbar>

      <md-app-drawer md-permanent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
            Menu
        </md-toolbar>
        <md-list>
            <router-link to="/user/info">
                <md-list-item>
                    <md-icon>info</md-icon>
                    <span class="md-list-item-text">Thông tin tài khoản</span>
                </md-list-item>
            </router-link>
            <router-link to="/user/change-password">
                <md-list-item>
                    <md-icon>lock_outline</md-icon>
                    <span class="md-list-item-text">Đổi mật khẩu</span>
                </md-list-item>
            </router-link>
        </md-list>
      </md-app-drawer>

      <md-app-content>
            <Info v-if="page == 'info'" />
            <ChangePassword v-if="page == 'change-password'"/>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import Info from './Info.vue'
import ChangePassword from './ChangePassword.vue'

export default {
    components: {
        Info,
        ChangePassword
    },
    data() {
        return {
            title: '',
            page: 'info'
        }
    },
    created() {
        this.page = this.$route.params.page;
        this.changeTitle();
    },
    methods: {
        onChangePage(page){
            this.page = page;
            this.changeTitle();
        },

        changeTitle(){
            if(this.page == 'info'){
                this.title = "Thông tin tài khoản";
            }
            else if(this.page == 'change-password'){
                this.title = "Thay đổi mật khẩu";
            }
        }
    },
    watch: {
        "$route.params.page"(value) {
            this.onChangePage(value);
        }
    }
}
</script>


<style lang="css" scoped>
    .md-app {
        min-height: calc(100vh - 110px);
        border: 1px solid rgba(#000, .12);
    }
    .md-drawer {
        width: 250px;
        max-width: calc(100vw - 145px);
    }
    .md-list-item-text {
        color: black;
    }
</style>
